import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import TextGroup from '../components/TextGroup'
import ErpComp from '../components/ErpComp'

const iManPage = () => (
<Layout>
<SEO title="MxStation" keywords={[`Nesting`, `Production Environment`, `react`]} />
    <div className="BlueGradient">
      <div className="--headerClear"></div>
      <section className="--sectionPaceM --centerMargin --maxwidthM --edgePadding --centerTxt --heroAnimation ">
        <h2>Take control of your production with <span>iManufacturing</span></h2>
        <p>Produce the parts you need when you need them</p>
      </section>
    </div>
    <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>Product Overview</h5>
        <p className="DarkP">From the intelligence of its lights-out automation tools to automatic shop floor data collection, iManufacturing increases productivity while reducing operator intervention, giving you unequalled production flexibility. Building block modules allow you to build the solution that meets your goals and grows as needed. iManufacturing synchronizes your ERP, engineering and CNC equipment into a single manageable system that provides real-time views of current, upcoming and completed production on the shop floor.</p>
      </article>
    </section>
    <ErpComp />
      <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid6040">
      <artcle>
        <h2 className="DarkH">‘Round the clock operations</h2>
        <p className="DarkP">iManufacturing puts you in charge, allowing you to produce parts on your terms.  iManufacturing provides unequalled visibility and control over your shop floor production. </p>
      </artcle>
      <div className="no_bullet">
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Color Coded Laser Status</p>
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Shift Management</p>
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Increased Awareness of Cell Effeciency</p>
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Event Notifications</p>

            </div>    
        </section>
        <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">
        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
</Layout>
)

export default iManPage
import React from "react";


const ErpComp = props => (
<section className="ERP Pattern">
<article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Ncell works with your existing ERP</h2>
          <p className="DarkP">Our products are designed to work with a variety of popular shop floor data systems, and can also collect and manage data independently.</p>
        </article>
        <div>
          <img alt="#" src={require('../images/SAP.png')}/>
          <img alt="#" src={require('../images/Epicore.png')}/>
          <img alt="#" src={require('../images/Infor.png')}/>
          <img alt="#" src={require('../images/Oracle.png')}/>
          <img alt="#" src={require('../images/customerlogos/globalshop.png')}/>
          <img alt="#" src={require('../images/customerlogos/jobboss.png')}/>
        </div>
        <p className="Footnote">Contact us for full support list.</p>
      </section>
)



export default ErpComp